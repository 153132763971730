import Dashboard from "./dashboard";
import Roles from "./user settings/roles";
import Users from "./user settings/users";
import Projects from "./work management/projects";
import Tasks from "./work management/tasks";
import Events from "./work management/events";

const routes = [
  {
    path: "/dashboard",
    component: Dashboard,
  },
  {
    path: "/roles",
    component: Roles,
  },
  {
    path: "/users",
    component: Users,
  },
  {
    path: "/projects",
    component: Projects,
  },
  {
    path: "/tasks",
    component: Tasks,
  },
  {
    path: "/events",
    component: Events,
  },
];

export default routes;
