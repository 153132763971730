import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBIcon,
  MDBModalHeader,
  MDBSelect,
  MDBSelectInput,
  MDBSelectOption,
  MDBSelectOptions,
  MDBRow,
  MDBCol,
  MDBInput,
} from "mdbreact";
import {
  ENDPOINT,
  PresetImage,
  calculateDiff,
  fullName,
} from "../../../../../services/utilities";
import Docx from "../../../../../components/docx";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import {
  SAVE,
  UPDATE,
  CUSTOMALERT,
} from "../../../../../services/redux/slices/projects";

export default function Modal({ show, toggle, selected, willCreate }) {
  const { collections } = useSelector(({ users }) => users),
    { isLoading, message, isSuccess } = useSelector(({ projects }) => projects),
    [developers, setDevelopers] = useState([]),
    [clients, setClients] = useState([]),
    { token } = useSelector(({ auth }) => auth),
    [editorState, setEditorState] = useState(() => EditorState.createEmpty()),
    [form, setForm] = useState({
      name: "",
      link: "",
      client: "",
      developers: [],
      duration: {
        start: "",
        end: "",
      },
      price: 0,
      status: selected.status ? selected.status : "pending",
    }),
    dispatch = useDispatch();

  useEffect(() => {
    const handleInject = async () => {
      if (selected._id) {
        let updates = {
          _id: selected._id,
          status: selected.status,
          developers: [],
          name: selected.name,
          duration: selected.duration,
          price: selected.price,
          link: selected.link,
          client: "",
        };

        if (selected.client && selected.client._id) {
          updates.client = selected.client._id;
        }

        if (selected.developers.length > 0) {
          await selected.developers.forEach(dev => {
            updates.developers.push(dev._id);
          });
        }

        if (selected.details) {
          setEditorState(
            EditorState.createWithContent(
              convertFromRaw(JSON.parse(selected.details))
            )
          );
        }

        setForm(updates);
      }
    };

    handleInject();
  }, [selected]);

  const handleUpdate = () =>
    dispatch(
      UPDATE({
        data: {
          ...form,
          details: JSON.stringify(
            convertToRaw(editorState.getCurrentContent())
          ),
        },
        token,
      })
    );

  const handleSave = () =>
    dispatch(
      SAVE({
        data: {
          ...form,
          details: JSON.stringify(
            convertToRaw(editorState.getCurrentContent())
          ),
        },
        token,
      })
    );

  const handleChange = (key, value) => setForm({ ...form, [key]: value });

  const handleSubmit = e => {
    e.preventDefault();

    if (form.client) {
      if (form.developers.length > 0) {
        if (calculateDiff(form.duration.start, form.duration.end) > 6) {
          if (willCreate) {
            console.log(calculateDiff(new Date(), form.duration.start));
            if (calculateDiff(new Date(), form.duration.start) >= 0) {
              handleSave();
            } else {
              dispatch(
                CUSTOMALERT(
                  "Invalid Date Selection: Please choose a future date."
                )
              );
            }
          } else {
            handleUpdate();
          }
          toggle();
        } else {
          dispatch(CUSTOMALERT("Duration must at least be 7 days."));
        }
      } else {
        dispatch(CUSTOMALERT("Please select at least one developer."));
      }
    } else {
      dispatch(CUSTOMALERT("Please choose a client."));
    }
  };

  useEffect(() => {
    setDevelopers(collections.filter(e => e.role?.name === "DEVELOPER"));
    setClients(collections.filter(e => e.role?.name === "CLIENT"));
  }, [collections]);

  const handleImage = () => document.getElementById("uploadImage").click();

  const handleImageChange = e => {
    document.getElementById("image").src = URL.createObjectURL(
      e.target.files[0]
    );
    const reader = new FileReader();
    reader.onload = e => {
      let image = new Image();
      image.src = e.target.result;

      image.onload = function () {
        handleChange("img", reader.result.split(",")[1]);
      };
    };
    reader.readAsDataURL(e.target.files[0]);
  };
  return (
    <MDBModal
      size="xl"
      isOpen={show}
      toggle={toggle}
      backdrop={true}
      disableFocusTrap={false}
    >
      <MDBModalHeader
        toggle={toggle}
        className="light-blue darken-3 white-text"
      >
        <MDBIcon icon="todo" className="mr-2" />
        {willCreate ? "Create" : "Update"} {selected.name || "a Project"}
      </MDBModalHeader>
      <MDBModalBody className="mb-0">
        <form onSubmit={handleSubmit}>
          <MDBRow>
            <MDBCol md="6">
              <MDBInput
                type="text"
                value={form.name}
                onChange={e =>
                  handleChange("name", e.target.value.toUpperCase())
                }
                label="Name"
                required
              />
              <MDBInput
                type="text"
                value={form.link}
                onChange={e => handleChange("link", e.target.value)}
                label="Link"
              />
              <MDBSelect
                getValue={e => handleChange("client", e[0])}
                className="colorful-select dropdown-primary hidden-md-down"
                label="Client"
              >
                <MDBSelectInput />
                <MDBSelectOptions>
                  {clients.map((client, index) => {
                    let isSelected = false;

                    if (selected._id) {
                      if (selected.client?._id === client._id) {
                        isSelected = true;
                      }
                    }

                    return (
                      <MDBSelectOption
                        key={`client-list-${index}`}
                        value={client._id}
                        selected={isSelected}
                      >
                        {fullName(client.fullName)}
                      </MDBSelectOption>
                    );
                  })}
                </MDBSelectOptions>
              </MDBSelect>
              <MDBRow>
                <MDBCol md="6">
                  <MDBInput
                    type="date"
                    value={form.duration.start}
                    onChange={e => {
                      const _duration = { ...form.duration };
                      _duration.start = e.target.value;
                      setForm({
                        ...form,
                        duration: _duration,
                      });
                    }}
                    label="Start"
                    disabled={!willCreate}
                    required
                  />
                </MDBCol>

                <MDBCol md="6">
                  <MDBInput
                    type="date"
                    value={form.duration.end}
                    onChange={e => {
                      const _duration = { ...form.duration };
                      _duration.end = e.target.value;
                      setForm({
                        ...form,
                        duration: _duration,
                      });
                    }}
                    label="End"
                    required
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol md="6">
                  <MDBInput
                    type="number"
                    value={form.price}
                    onChange={e =>
                      handleChange("price", Number(e.target.value))
                    }
                    label="Price"
                    min={1}
                    required
                  />
                </MDBCol>

                <MDBCol md="6">
                  <MDBSelect
                    getValue={e => handleChange("status", e[0])}
                    className="colorful-select dropdown-primary hidden-md-down"
                    label="Status"
                  >
                    <MDBSelectInput />
                    <MDBSelectOptions>
                      <MDBSelectOption
                        selected={form.status === "pending"}
                        value="pending"
                      >
                        Pending
                      </MDBSelectOption>
                      <MDBSelectOption
                        selected={form.status === "ongoing"}
                        value="ongoing"
                      >
                        On Going
                      </MDBSelectOption>
                      <MDBSelectOption
                        selected={form.status === "done"}
                        value="done"
                      >
                        Done
                      </MDBSelectOption>
                      <MDBSelectOption
                        selected={form.status === "published"}
                        value="published"
                      >
                        Publish
                      </MDBSelectOption>
                    </MDBSelectOptions>
                  </MDBSelect>
                </MDBCol>
              </MDBRow>
              <MDBSelect
                multiple
                getValue={e => handleChange("developers", e)}
                className="colorful-select dropdown-primary hidden-md-down"
                label="Developers"
              >
                <MDBSelectInput />
                <MDBSelectOptions>
                  {developers.map((devs, index) => {
                    let isSelected = false;

                    if (selected._id) {
                      const _devs = selected.developers;
                      if (
                        _devs.length > 0 &&
                        _devs.some(e => e._id === devs._id)
                      ) {
                        isSelected = true;
                      }
                    }

                    return (
                      <MDBSelectOption
                        key={`devs-list-${index}`}
                        value={devs._id}
                        selected={isSelected}
                      >
                        {fullName(devs.fullName)}
                      </MDBSelectOption>
                    );
                  })}
                </MDBSelectOptions>
              </MDBSelect>
            </MDBCol>
            <MDBCol md="6">
              <Docx
                editorState={editorState}
                _className="mt-5 border"
                setEditorState={setEditorState}
                _style={{ minHeight: "230px" }}
              />
            </MDBCol>
          </MDBRow>

          <MDBCol md={"12"} className="mt-2">
            <div
              className="bg-image mx-auto hover-overlay"
              style={{ maxWidth: 400 }}
            >
              <img
                id="image"
                src={`${ENDPOINT}/assets/projects/${form._id}.png`}
                style={{ width: 400, height: 200 }}
                className="img-fluid"
                alt={"project"}
                onError={e => (e.target.src = PresetImage(false))}
              />
              <a href="#!">
                <div
                  className="mask overlay d-flex align-items-center"
                  // style={{ backgroundColor: "rgba(57, 192, 237, 0.2)" }}
                >
                  <input
                    type="file"
                    id="uploadImage"
                    style={{ display: "none" }}
                    onChange={handleImageChange}
                    accept="image/png"
                  />
                  <MDBBtn
                    onClick={handleImage}
                    color="success"
                    className="mx-auto"
                  >
                    <MDBIcon icon="upload" />
                  </MDBBtn>
                </div>
              </a>
            </div>
          </MDBCol>
          {message && (
            <div
              className={`alert alert-${
                isSuccess ? "success" : "warning"
              } text-center mt-3`}
            >
              {message}
            </div>
          )}
          <div className="text-center mb-1-half">
            <MDBBtn
              type="submit"
              disabled={isLoading}
              color="info"
              className="mb-2"
              rounded
            >
              {willCreate ? "submit" : "update"}
            </MDBBtn>
          </div>
        </form>
      </MDBModalBody>
    </MDBModal>
  );
}
