const sidebar = [
  {
    name: "Dashboard",
    icon: "tachometer-alt",
    path: "/dashboard",
  },
  {
    name: "Projects",
    icon: "cogs",
    path: "/projects",
  },
];

export default sidebar;
