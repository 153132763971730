import { MDBCard, MDBCardBody, MDBIcon, MDBMask, MDBView } from "mdbreact";
import React from "react";
import { ENDPOINT } from "../../../../services/utilities";

export default function Card({ project, index }) {
  return (
    <MDBCard className="mb-5" narrow>
      <MDBView cascade hover>
        <img
          style={{ width: "600px", height: "250px" }}
          src={`${ENDPOINT}/assets/projects/${project._id}.png`}
          className="img-fluid w-100"
          alt="project one"
          onError={e =>
            (e.target.src =
              "https://mdbootstrap.com/img/Mockups/Horizontal/6-col/pro-profile-page.jpg")
          }
        />
        <a href="#!">
          <MDBMask overlay="white-slight" />
        </a>
      </MDBView>
      <MDBCardBody>
        <h4 className="card-title">{project.name}</h4>
        {JSON.parse(project.details).blocks.map((block, index) => {
          return (
            <p className="card-text" key={`p-${index}`}>
              {block.text}
            </p>
          );
        })}
      </MDBCardBody>
      {project.link && (
        <div
          data-test="card-footer"
          className="bg-transparent card-footer links-light "
        >
          <span className="float-right">
            <a
              target={project.link && "_blank"}
              href={project.link}
              className="waves-effect p-2"
            >
              Live Preview <MDBIcon icon="image" className="ml-1" />
            </a>
          </span>
        </div>
      )}
    </MDBCard>
  );
}
