import React from "react";
import Phone from "../../../assets/landing/phone.jpg";
import { MDBRow, MDBCol, MDBIcon, MDBAnimation } from "mdbreact";

export default function Description() {
  return (
    <section className="section my-5wow fadeIn" data-wow-delay="0.3s">
      <MDBAnimation type="fadeInUp" reveal>
        <h1 className="text-center my-5 h1">Why is it so great?</h1>
      </MDBAnimation>
      <MDBAnimation type="fadeInUp" reveal>
        <p className="text-center mb-5 w-responsive mx-auto">
          CapstoneConnect is designed with your needs in mind, offering a
          straightforward solution to alleviate financial burdens associated
          with capstone projects
        </p>
      </MDBAnimation>
      <MDBRow>
        <MDBCol md="4">
          <MDBRow className="mb-2">
            <MDBCol size="2">
              <MDBAnimation reveal type="fadeInLeftBig" duration="1.25s">
                <MDBIcon size="2x" className="indigo-text" icon="brain" />
              </MDBAnimation>
            </MDBCol>
            <MDBCol size="10">
              <MDBAnimation reveal type="fadeInLeftBig">
                <h5 className="font-weight-bold my-4">Ease of Mind</h5>
                <p className="grey-text">
                  We believe that students should focus on creating impactful
                  capstone projects rather than navigating payment complexities.
                  CapstoneConnect provides the peace of mind you need.
                </p>
              </MDBAnimation>
            </MDBCol>
          </MDBRow>

          <MDBRow className="mb-2">
            <MDBCol size="2">
              <MDBAnimation reveal type="fadeInLeftBig" duration="1.25s">
                <MDBIcon size="2x" className="blue-text" icon="flask" />
              </MDBAnimation>
            </MDBCol>
            <MDBCol size="10">
              <MDBAnimation reveal type="fadeInLeftBig">
                <h5 className="font-weight-bold my-4">Project Excellence</h5>
                <p className="grey-text">
                  When financial barriers are removed, you're free to dedicate
                  your energy to producing exceptional capstone projects that
                  reflect your true potential.
                </p>
              </MDBAnimation>
            </MDBCol>
          </MDBRow>
        </MDBCol>
        <MDBCol md="4 mb-2 text-center text-md-left flex-center">
          <MDBAnimation reveal type="fadeInUp">
            <img src={Phone} alt="" className="z-depth-0" />
          </MDBAnimation>
        </MDBCol>

        <MDBCol md="4">
          <MDBRow className="mb-2">
            <MDBCol size="10" className="text-right">
              <MDBAnimation reveal type="fadeInRightBig">
                <h5 className="font-weight-bold my-4">Simplified Process</h5>
                <p className="grey-text">
                  No more unnecessary hassles. CapstoneConnect streamlines
                  payments, making the process straightforward and hassle-free.
                </p>
              </MDBAnimation>
            </MDBCol>
            <MDBCol size="2">
              <MDBAnimation reveal type="fadeInRightBig" duration="1.25s">
                <MDBIcon
                  size="2x"
                  icon="spinner"
                  className="deep-purple-text"
                />
              </MDBAnimation>
            </MDBCol>
          </MDBRow>

          <MDBRow className="mb-2">
            <MDBCol size="10" className="text-right">
              <MDBAnimation reveal type="fadeInRightBig">
                <h5 className="font-weight-bold my-4">
                  Student-Centric Approach
                </h5>
                <p className="grey-text">
                  We're dedicated to your success. Our platform is designed to
                  empower you, ensuring you have access to the resources you
                  need to excel.
                </p>
              </MDBAnimation>
            </MDBCol>
            <MDBCol size="2">
              <MDBAnimation reveal type="fadeInRightBig" duration="1.25s">
                <MDBIcon
                  size="2x"
                  icon="user-graduate"
                  className="text-warning"
                />
              </MDBAnimation>
            </MDBCol>
          </MDBRow>
        </MDBCol>
      </MDBRow>
    </section>
  );
}
