import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  UPDATE,
  FIND,
  RESET,
} from "../../../../services/redux/slices/projects";
import {
  FIND as USERS,
  RESET as USERSRESET,
} from "../../../../services/redux/slices/users";
import {
  calculateDiff,
  fullName,
  globalSearch,
} from "../../../../services/utilities";
import { useToasts } from "react-toast-notifications";
import Swal from "sweetalert2";
import Modal from "./modal";
import DataTable from "../../../../components/dataTable";
import { MDBBadge } from "mdbreact";

export default function Projects() {
  const [isActive, setIsActive] = useState(true),
    [projects, setProjects] = useState([]),
    [selected, setSelected] = useState({}),
    [willCreate, setWillCreate] = useState(true),
    [showModal, setShowModal] = useState(false),
    { auth, token } = useSelector(({ auth }) => auth),
    { collections, message, isSuccess, isLoading } = useSelector(
      ({ projects }) => projects
    ),
    { addToast } = useToasts(),
    dispatch = useDispatch();

  const handleSwitch = () => setIsActive(!isActive);

  useEffect(() => {
    if (token) {
      dispatch(FIND({ token, key: { client: auth._id } }));
      dispatch(
        USERS({
          token,
          key: {
            role: `{"$in": ["647dd209dced91b0b39444af", "647dd9120874515a8fc47afb"]}`,
          },
        })
      );
    }

    return () => {
      dispatch(RESET());
      dispatch(USERSRESET());
    };
  }, [token, auth, dispatch]);

  useEffect(() => {
    setProjects(collections.filter(e => e.wasRejected === !isActive));
  }, [isActive, collections]);

  const toggleModal = () => {
    if (selected._id) {
      setSelected({});
    }
    if (message) {
      dispatch(RESET());
    }
    setShowModal(!showModal);
  };

  const handleUpdate = selected => {
    setSelected(selected);
    if (willCreate) {
      setWillCreate(false);
    }
    toggleModal();
  };

  const handleRestore = selected =>
    Swal.fire({
      title: "Are you sure?",
      text: `You are about to restore ${selected.length} Project(s).`,
      icon: "question",
      confirmButtonText: "Proceed",
    }).then(res => {
      if (res.isConfirmed) {
        const toRestore = selected.map(item => ({
          _id: item._id,
          wasRejected: false,
          rejected: {},
        }));

        dispatch(
          UPDATE({
            data: toRestore.length > 1 ? toRestore : toRestore[0],
            token,
          })
        );
      }
    });

  useEffect(() => {
    if (message) {
      addToast(message, {
        appearance: isSuccess ? "success" : "error",
      });
    }
  }, [isSuccess, message, addToast]);

  const handleSearch = async (willSearch, key) => {
    if (willSearch) {
      setProjects(globalSearch(projects, key));
    } else {
      setProjects(collections.filter(e => e.wasRejected === !isActive));
    }
  };

  return (
    <>
      <DataTable
        isLoading={isLoading}
        title={`${isActive ? "Active" : "Rejected"} Projects`}
        array={projects}
        actions={[
          {
            _icon: "sync",
            _function: handleSwitch,
            _shouldReset: true,
            _disabledOnSearch: true,
          },
          {
            _icon: isActive ? "eye" : "undo-alt",
            _function: isActive ? handleUpdate : handleRestore,
            _haveSelect: true,
            _allowMultiple: !isActive,
            _shouldReset: true,
          },
        ]}
        tableHeads={[
          {
            _text: "Name",
          },
          {
            _text: "Client",
          },
          {
            _text: "Phase",
          },
          {
            _text: "Duration",
          },
          {
            _text: "Reason",
            _condition: () => !isActive,
          },
          {
            _text: "Date",
            _condition: () => !isActive,
          },
        ]}
        tableBodies={[
          {
            _key: "name",
          },
          {
            _key: "client",
            _format: data => fullName(data.fullName),
          },
          {
            _key: "status",
            _format: data => (
              <MDBBadge
                color={
                  data === "pending"
                    ? "info"
                    : data === "ongoing"
                    ? "primary"
                    : data === "published"
                    ? "secondary"
                    : "success"
                }
                className="text-capitalize p-1"
              >
                {data}
              </MDBBadge>
            ),
          },
          {
            _key: "duration",
            _format: data => {
              return `${calculateDiff(data.start, data.end)} days`;
            },
          },
          {
            _key: "rejected",
            _format: data => data?.for,
            _condition: () => !isActive,
          },
          {
            _key: "rejected",
            _format: data => data?.at,
            _condition: () => !isActive,
          },
        ]}
        handleSearch={handleSearch}
      />
      <Modal
        key={selected._id || "project-initial-create"}
        selected={selected}
        willCreate={willCreate}
        show={showModal}
        toggle={toggleModal}
      />
    </>
  );
}
