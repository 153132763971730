const sidebar = [
  {
    name: "Dashboard",
    icon: "tachometer-alt",
    path: "/dashboard",
  },
  {
    name: "User Settings",
    icon: "user-cog",
    children: [
      {
        name: "Roles",
        path: "/roles",
      },
      {
        name: "Users",
        path: "/users",
      },
    ],
  },
  {
    name: "Work Management",
    icon: "city",
    children: [
      {
        name: "Projects",
        path: "/projects",
      },
      {
        name: "Tasks",
        path: "/tasks",
      },
      {
        name: "Events",
        path: "/events",
      },
    ],
  },
];

export default sidebar;
