import React, { useEffect, useState } from "react";
import { MDBContainer, MDBCardGroup } from "mdbreact";
import { useDispatch, useSelector } from "react-redux";
import { FIND, RESET } from "../../../../services/redux/slices/projects";
import Card from "./card";

export default function Dashboard() {
  const [projects, setProjects] = useState([]),
    { token } = useSelector(({ auth }) => auth),
    { collections } = useSelector(({ projects }) => projects),
    dispatch = useDispatch();
  useEffect(() => {
    if (token) {
      dispatch(FIND({ token, key: { status: "published" } }));
    }

    return () => {
      dispatch(RESET());
    };
  }, [token, dispatch]);

  useEffect(() => {
    setProjects(collections.filter(e => e.wasRejected === false));
  }, [collections]);
  return (
    <MDBContainer fluid id="v6" className="mb-5">
      <section className="mb-4">
        {projects.map((project, index) => {
          if (projects.length > index + 1) {
            if (index % 2 === 0) {
              return (
                <MDBCardGroup
                  deck
                  key={`${project.name}-MDBCardGroup-${index}`}
                >
                  <Card project={project} key={`item-${index}`} />
                  <Card
                    project={projects[index + 1]}
                    key={`item-${index + 1}`}
                  />
                </MDBCardGroup>
              );
            }
            return <></>;
          } else {
            if (projects.length % 2 !== 0) {
              return (
                <MDBCardGroup
                  deck
                  key={`${project.name}-MDBCardGroup-${index}`}
                >
                  <Card project={project} key={`item-${index}`} />
                </MDBCardGroup>
              );
            }
            return <></>;
          }
        })}
      </section>
    </MDBContainer>
  );
}
