const sidebar = [
  {
    name: "Dashboard",
    path: "/dashboard",
    icon: "tachometer-alt",
  },
  {
    name: "Work Management",
    icon: "city",
    children: [
      {
        name: "Projects",
        path: "/projects",
      },
      {
        name: "Tasks",
        path: "/tasks",
      },
    ],
  },
  {
    name: "Trainings",
    icon: "user-graduate",
    children: [
      {
        name: "Courses",
        path: "/courses",
      },
      {
        name: "Lessons",
        path: "/lessons",
      },
      {
        name: "Activities",
        path: "/activities",
      },
    ],
  },
  {
    name: "Financials",
    icon: "credit-card",
    children: [
      {
        name: "Deployments",
        path: "/deployments",
      },
      {
        name: "Utilities",
        path: "/utilities",
      },
      {
        name: "Expenses",
        path: "/expenses",
      },
      {
        name: "Savings",
        path: "/savings",
      },
    ],
  },
];

export default sidebar;
