const locations = location => {
  switch (location) {
    case "/dashboard":
      return "Dashboard";

    case "/roles":
      return "Listed Roles";

    case "/users":
      return "Listed Users";

    case "/projects":
      return "Listed Projects";

    case "/tasks":
      return "Listed Tasks";

    case "/profile":
      return "Your Profile";

    case "/events":
      return "Calendar Events";

    default:
      return "Looks like you're lost.";
  }
};

export default locations;
